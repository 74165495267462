<template>
    <Dialog ref="dialog" :config="config" :beforeClose="beforeClose" :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='confirmDialog' :isdeling='isdeling'>
        <p class="title">您将要永久删除回收站中的所有账套</p>
        <p class="text">
            注意：此操作将在回收站永久删除，无法还原账套信息
        </p>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog";
export default {
  props: {
    rowData:{
      type: Object,
    },
    type:{
      type: String,
    },
    visible: {
      type: Boolean,
    },
    isdeling: {
      type: Boolean,
    },
    cancelDialog:{
      type: Function,
      default: () => {},
    },
    confirmDialog:{
      type: Function,
      default: () => {},
    }
  },
  components: {
    Dialog,
  },
  data() {
    return {
      config: {
        top: '20vh',
        width: '21rem',
        title: '清空回收站',
        center: true,
        btnTxt: ['确认','取消'],
      },
    };
  },
  methods: {
    beforeClose(){
      console.log('关闭前');
    },
  }
};
</script>

<style lang="less" scoped>
  .title{
    color: #FF0000;
    font-size: 16px;
  }
  .text{
    color: #333333;
    font-size: 14px;
    margin: 6px 0;
  }
</style>
